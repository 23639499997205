import React, { useState } from "react";
import { Link } from "gatsby";

const ServiceTab = ({ tabs, mode, containWidth, onChangeTab, activeUs, activeThis, light }) => {
  const [active, setActive] = useState(activeUs ? activeUs : 0);
  const onChangeTabs = (data) => {
    const finalData = data.replace(/\s*\(\d+\)\s*$/, "");
    onChangeTab(finalData);
  };
  console.log(activeUs, 'activeUs')
  return (
    <section
      className={`${
        mode === "dark" ? light ? "lg:py-[30px] py-[20px]" : "" : "lg:py-[30px] py-[20px]"
      } text-white relative ${containWidth ? "transparent" : "bg-[#121212]"}`}
    >
      <div
        className={`tabs-list flex items-center pr-[30px] ${
          containWidth
            ? "flex-wrap !ml-[0] lg:pr-[30px] pr-[0]"
            : "marquee-scroll"
        } ${"gap-[20px]"} ${activeThis >= 0 ? '!flex-nowrap md:!flex-wrap overflow-x-scroll md:overflow-x-auto ' : ''} moving-scroll`}
      >
        {tabs.map((item, index) => (
          <div key={index} className="relative">
            {!containWidth ? (
              <Link
                to={item.link}
                className="w-full rounded-[30px] relative lg:py-[10px] md:py-[8px] py-[6px] md:px-[20px] px-[12px] cursor-pointer select-none overflow-hidden min-w-fit"
              >
                <p className="text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] select-none whitespace-nowrap">
                  {item.text}
                </p>
              </Link>
            ) : 
            activeThis >= 0 ?
            (
              <button
                className={`w-full rounded-[30px] relative lg:py-[10px] md:py-[8px] py-[6px] md:px-[20px] px-[12px] cursor-pointer select-none overflow-hidden min-w-fit 
                ${mode === "dark" ? "dark" : ""} ${
                  activeThis === index ? "active" : ""
                }`}
                onClick={() => {
                  onChangeTabs(item);
                }}
              >
                <p
                  className={`text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] select-none whitespace-nowrap`}
                >
                  {item}
                </p>
              </button>
            ) :
            (
              <button
                className={`w-full rounded-[30px] relative lg:py-[10px] md:py-[8px] py-[6px] md:px-[20px] px-[12px] cursor-pointer select-none overflow-hidden min-w-fit 
                ${mode === "dark" ? "dark" : ""} ${
                  active === index ? "active" : ""
                }`}
                onClick={() => {
                  onChangeTabs(item);
                  setActive(index);
                }}
              >
                <p
                  className={`text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] select-none whitespace-nowrap`}
                >
                  {item}
                </p>
              </button>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServiceTab;
