import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
// import KnowMoreSection from "../components/KnowMoreSection";
import Cta from "../components/service/Cta";
import { GatsbyImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import ServiceTab from "../components/ServiceTab";
import { navigate } from "@reach/router";
import Layout from "../components/layout";
import dayjs from "dayjs";
import LinkArrowSvg from "../images/link-arrow";
import loadingGif from "../videos/loading-submit.gif";
import SubscriptionForm from "../components/contact/SubscriptionForm";

export default function Blog({ data }) {
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const increaseLimit = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setLimit(prevLimit => prevLimit + 10);
    }, 1000);
  };
  const dataCta = { title: "Don’t want to miss anything?" };
  const tabs = ["Latest", "Insights", "Inspirations", "Tutorials"];
  const [selectedTab, setSelectedTab] = useState("Latest");
  const onChangeTab = (data) => {
    setSelectedTab(data);
  };
  const recentPosts = data.recentQuery.edges;
  useEffect(() => {
    if (selectedTab === "Tutorials") {
      navigate("/blogs/tutorials/");
    } else if (selectedTab === "Insights") {
      navigate("/blogs/insights/");
    } else if (selectedTab === "Inspirations") {
      navigate("/blogs/inspirations/");
    } else {
      navigate("/blogs");
    }
  }, [selectedTab]);
  // Dates
  const formattedDates = recentPosts.map((post) => {
    const dateStr = post.node.date;
    const dateTime = dayjs(dateStr);
    const formattedDate = dateTime.format("MMM D, YYYY");
    return formattedDate;
  });
  const visiblePosts = recentPosts.slice(0, limit);
  // console.log(visiblePosts.length, recentPosts.length, '')
  return (
    <>
      <Layout>
        <section className="lg:pt-[80px] md:pt-[60px] pt-[40px] lg:pb-[200px] md:pb-[120px] pb-[80px] fade-in-animation">
          <div className="container">
            <div className="lg:mb-[80px] md:mb-[70px] mb-[60px] first-comesup">
              <div className="flex justify-between items-center">
                <ServiceTab
                  tabs={tabs}
                  containWidth
                  mode="dark"
                  onChangeTab={onChangeTab}
                />
                <div className="tabs-list">
                  <div>
                    <Link href='/write-for-us' className="dark rounded-[30px] relative lg:py-[10px] md:py-[8px] py-[6px] md:px-[20px] px-[12px] cursor-pointer overflow-hidden min-w-fit text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] select-none whitespace-nowrap">Write for us</Link>
                  </div>
                </div>
              </div>
            </div>
            <h1
              className={`lg:text-[60px] first-comesup lg:leading-[84px] font-semibold lg:max-w-[800px] font-heading lg:mb-[80px] md:mb-[70px] mb-[60px] md:text-[50px] text-[38px] leading-[1.36]`}
            >
              {selectedTab === "Latest"
                ? "Latest UI UX Design Ideas, Inspirations, & Insights"
                : selectedTab}
            </h1>
            <div
              className={`flex flex-col lg:gap-[10px] md:gap-[10px] gap-[10px] blogs-list second-comesup`}
            >
              {visiblePosts.map(({ node }, index) => (
                <div key={index}>
                  {node.categories.nodes.map((category) => (
                    <Link
                      key={category.slug}
                      to={`/blogs/${category.slug}/`}
                      className="text-[15px] leading-[24px] tracking-[.75px] underline mb-[20px] underline-offset-4 hover:no-underline inline-block"
                    >
                      {category.name}
                    </Link>
                  ))}
                  <Link
                    to={`/${node.slug}/`}
                    className="lg:pb-[70px] blog-link-tag mb-[70px] md:pb-[60px] pb-[80px] blog-div flex gap-[30px] lg:gap-[0] justify-between md:items-center flex-col md:flex-row"
                    key={index}
                  >
                    <div className="max-w-[600px] md:w-[80%] w-[100%]">
                      <h2 className="md:text-[34px] text-unset lg:leading-[56px] text-[26px] leading-[36px] font-semibold mb-[10px]">
                        {node.title}
                      </h2>
                      <div
                        className="eliple ibm lg:line-clamp-2 line-clamp-4 lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] mb-[35px]"
                        dangerouslySetInnerHTML={{ __html: node.excerpt }}
                      ></div>
                      <p className="text-[#2A2A2A] info font-medium md:leading-[22px] md:text-[14px] text-[12px] tracking-[.05em] block leading-[20px] link-text-para">
                        {formattedDates[index]} • By{" "}
                        {node.author.node.name}
                      </p>
                    </div>
                    <div className="md:w-[327px] w-[100%] blog-list-image">
                      {node.featuredImage && node.featuredImage.node && (
                        <>
                          <GatsbyImage
                            image={node.featuredImage.node.gatsbyImage}
                            alt={
                              node.featuredImage.node.altText
                                ? node.featuredImage.node.altText
                                : "blogs"
                            }
                            title={node.featuredImage.node.title}
                          />
                        </>
                      )}
                    </div>
                  </Link>
                </div>
              ))}
              <button onClick={increaseLimit}
                className="relative font-medium font-heading load-more-button lg:pr-[51px] md:pr-[40px] pr-[36px] lg:text-[20px] md:text-[16px] text-[14px] md:leading-[19.36px] lg:leading-[24.2px] leading-[16.9px] transition hover:transition-all duration-300 hover:duration-300 flex items-center max-w-fit"
              >
                {visiblePosts.length !== recentPosts.length ? loading ? "Loading..." : 'Load More Blogs' : 'You’ve reached the end of this list'}
                {
                  visiblePosts.length !== recentPosts.length ? loading ?
                  <img
                            className="loading-gif"
                            src={loadingGif}
                            alt="sending"
                          /> :
                    <span className="absolute top-[50%] -translate-y-[50%] left-[calc(100%-26px)] md:left-[calc(100%-28px)] lg:left-[calc(100%-36px)] flex items-center justify-center w-[30px] h-[30px] lg:w-[40px] lg:h-[40px] bg-[#E9EEED] hover:transition-all transition-all duration-300 hover:duration-300 rounded-[50px]">
                      <div className="rotate-[135deg]">
                        <LinkArrowSvg size={13} fill="#434343" />
                      </div>
                    </span>
                    : ''
                }
              </button>
              
            </div>
          </div>
        </section>
        {/* <KnowMoreSection nin="Blogs" Include="services" /> */}
      </Layout>
      <SubscriptionForm />
    </>
  );
}
export const Head = () => (
  <Seo
    title="Blogs on UI UX Design | UI UX Services | Octet Design Studio"
    description="Get to know about trending ideas, inspirations, insights and strategies about UI UX Designs. Read and learn from the experts at Octet Design Studio."
  />
);
export const pageQuery = graphql`
  query {
    recentQuery: allWpPost(sort: { date: DESC } filter: {tags: { nodes: { elemMatch: { name: { ne: "NoBlog" } } } }}) {
      edges {
        node {
          title
          excerpt
          slug
          date
          seo {
            readingTime
          }
          featuredImage {
            node {
              gatsbyImage(formats: WEBP, width: 400, placeholder: BLURRED)
              altText
              title
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
          author {
            node {
              name
            }
          }
        }
      }
    }
  }
`;